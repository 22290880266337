<template>
  <TransparentModal>
    <template v-slot:button="{openModal}">
      <button class="btn d-flex flex-column justify-content-center align-items-center" @click="openModal" style="text-decoration:underline; color: white; font-weight: 900">
        VER EJEMPLO DE TICKET VÁLIDO
      </button>
    </template>
    <div class="d-flex flex-column justify-content-center align-items-center bg-light rounded-4">
      <div class="d-flex flex-column w-100 text-start p-md-3 p-lg-5 px-3 py-4" style="padding-top:10px;">
        <h2 class="text-black modal__title">
          Ejemplo de ticket
        </h2>
        <img class="w-100 shadow" :src="`/public-assets/ticket-tendero-valido`">
      </div>
    </div>
  </TransparentModal>
</template>

<script>

import TransparentModal from "@components/misc/TransparentModal.vue";

export default {
  name: "SpecialEditionModal",
  components: { TransparentModal }
}
</script>

<style scoped>

.modal__title {
  font-weight: 800;
  font-size: 26px;
  font-family: var(--font-family-title);
}

@media (max-width: 770px) {
  .modal__title {
    font-size: 16px;
  }
}

</style>