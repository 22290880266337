<template>
  <slot name="button" :openModal="openModal">
    <button class="btn rounded-pill border" @click="openModal">
      {{label}}
    </button>
  </slot>
  <transition name="overlay" appear>
    <div class="modal-overlay margin-modal" v-show="showModal" @click="closeModal">
      <div class="modal-content" @click.stop>
        <button type="button" class="p-2 btn close-button d-flex justify-content-end align-items-end" aria-label="Close" @click="closeModal">
          <i class="bi bi-x" style="font-weight:900; color: #000000"></i>
        </button>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "TransparentModal",
  props: {
    label: {
      required: false,
      default: 'Open Modal',
      type: String
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  watch: {
    showModal(newValue) {
      if (newValue) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    }
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    }
  },
  beforeDestroy() {
    // Ensure to reset the overflow property if the component is destroyed
    document.body.style.overflow = '';
  }
};
</script>

<style scoped>
.close-button {
  margin-top: calc(var(--header-height) / 2);
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  padding: 1em;
}

.modal-content {
  background: transparent;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  aspect-ratio: 16/9;
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
}

.close-button {
  cursor: pointer;
  position: absolute;
  right: 2rem;
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 2px;
  border: 0;
  text-transform: none;
  font-family: inherit;
  font-size: 30px; /* Adjust the value to make the font smaller */
  line-height: 30px;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-button:hover {
  transform: scale(1.1);
}

/* Transition classes */
.overlay-enter-active, .overlay-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.overlay-enter-from, .overlay-leave-to {
  opacity: 0;
}

.overlay-enter-to, .overlay-leave-from {
  opacity: 1;
}

.margin-modal {
  padding-top: var(--header-height);
}
.bi.bi-x::before {
  font-weight: 900 !important;
  font-size: 30px!important;
}

@media (max-width: 768px) {
  .bi.bi-x[data-v-6f4c9a33]::before {
    font-size: 24px !important;
  }
}


</style>
